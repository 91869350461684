import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Dialog, DialogTitle, DialogContent, TextField, FormControl, TableCell, 
  Table,TableBody, TableContainer,TableHead, TableRow,Paper, CircularProgress } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columns } from './scratchpadFeedColumns';
import gridOptionsDefault from '../../components/global/AGGrid_Options';
import AGGridSidebar from '../../components/global/AGGrid_Sidebar';

const ScratchpadFeed = () => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [compareResults, setCompareResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [compareData, setCompareData] = useState({
    country: '',
    line: '',
    days: '',
    startDate: ''
  });

  const handleCompareModalOpen = (rowData) => {
    console.log('Full rowData:', rowData);
    let formattedDate;
    if (rowData.updated_at) {
        const date = new Date(rowData.updated_at);
        formattedDate = date.toISOString().split('T')[0];
    } else {
        formattedDate = new Date().toISOString().split('T')[0];
    }
    console.log('Using date:', formattedDate);

    setIsCompareModalOpen(true);
    setCompareData(prev => ({
        ...prev,
        country: rowData.country,
        line: rowData.line,
        startDate: formattedDate
    }));
  };

  const handleCompareModalClose = () => {
    setIsCompareModalOpen(false);
    setCompareData({
      country: '',
      line: '',
      days: '',
      startDate: ''
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCompareData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const fetchCompareData = async (compareData) => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/experiments/line_performance_v1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: [compareData.country],
          lines: [compareData.line],
          start_date: compareData.startDate,
          days: compareData.days || 10, 
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch comparison data');
      }
  
      const data = await response.json();
      setCompareResults(data);
    } catch (error) {
      console.error('Error fetching comparison data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmit = () => {
    fetchCompareData(compareData);
  };

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await fetch('/api/scratchpad/notes/all');
        if (!response.ok) {
          throw new Error('Failed to fetch notes');
        }
        const data = await response.json();
        setNotes(data);
      } catch (error) {
        console.error('Error fetching notes:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, []);

  const CompareResults = ({ data }) => {
    if (!data || data.length === 0) return null;
    const formatPeriodDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
      });
    };
    const beforeStart = data[0]?.period_start;
    const afterStart = data[1]?.period_start;
    
    const beforeRow = data.find(row => row.period === 'Before');
    const afterRow = data.find(row => row.period === 'After');

    const calculateChange = (after, before) => {
      if (!before || before === 0) return '0.0';
      return ((after - before) / before * 100).toFixed(1);
  };

  const changeRow = {
      period: 'Change%',
      country_code: '--',
      line: '--',
      total_impressions: calculateChange(afterRow.total_impressions, beforeRow.total_impressions),
      total_clicks: calculateChange(afterRow.total_clicks, beforeRow.total_clicks),
      total_ads_cost: calculateChange(afterRow.total_ads_cost, beforeRow.total_ads_cost),
      total_ads_sales: calculateChange(afterRow.total_ads_sales, beforeRow.total_ads_sales),
      total_ads_orders: calculateChange(afterRow.total_ads_orders, beforeRow.total_ads_orders),
      total_units: calculateChange(afterRow.total_units, beforeRow.total_units),
      total_orders: calculateChange(afterRow.total_orders, beforeRow.total_orders),
      total_sales: calculateChange(afterRow.total_sales, beforeRow.total_sales),
      ctr: calculateChange(afterRow.ctr, beforeRow.ctr),
      cpc: calculateChange(afterRow.cpc, beforeRow.cpc),
      conversion_rate: calculateChange(afterRow.conversion_rate, beforeRow.conversion_rate),
      acos: calculateChange(afterRow.acos, beforeRow.acos),
      tacos: calculateChange(afterRow.tacos, beforeRow.tacos)
   };
   const getChangeColor = (value) => {
      if (value === '--') return 'inherit';
      const numValue = parseFloat(value);
      return numValue > 0 ? 'green' : numValue < 0 ? 'red' : 'inherit';
   };

    return (
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        {/* Date information */}
        <Box sx={{  mb: 2,  padding: '8px 16px', backgroundColor: '#f5f5f5', borderRadius: '4px', fontSize: '0.9rem', color: '#333333' }}>
          <span>Before Period Start Date: {formatPeriodDate(beforeStart)}</span>
          <span style={{ margin: '0 16px' }}>  |  </span>
          <span>After Period Start Date: {formatPeriodDate(afterStart)}</span>
        </Box>
        <TableContainer component={Paper} sx={{ 
        maxHeight: '600px', minHeight : '200px', minWidth : '1000px',  margin: 'auto', alignItems: 'center',
        '& .MuiTableCell-root': { textAlign: 'center',padding: '8px 12px', }, 
        '& .MuiTable-root': { borderCollapse: 'separate', borderSpacing: '0 2px', },
        border: '2px solid #e0e0e0', 
        borderRadius: '10px', padding: '16px', 
       }}>
        <Table size="large">
        <TableHead>
          <TableRow 
          sx={{backgroundColor: '#f5f5f5','& th': { fontWeight: 'bold', color: '#333333', fontSize: '0.8rem',  padding: '12px 16px' } }} >
            <TableCell>Period</TableCell> <TableCell>Country</TableCell> <TableCell>Lines</TableCell>
            <TableCell>Impressions</TableCell>
            <TableCell>Clicks</TableCell>
            <TableCell>Ads Cost</TableCell>
            <TableCell>Ads Sales</TableCell>
            <TableCell>Ads Orders</TableCell>
            <TableCell>Units</TableCell>
            <TableCell>Orders</TableCell>
            <TableCell>Sales</TableCell>
            <TableCell>CTR</TableCell>
            <TableCell>CPC</TableCell>
            <TableCell>Conv. Rate</TableCell>
            <TableCell>ACOS</TableCell>  
            <TableCell>TACOS</TableCell>
          </TableRow>
        </TableHead>
            <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} sx={{
              '& td': { fontSize: '1rem', fontWeight: '500' }
              }}>
              <TableCell>{row.period}</TableCell>
              <TableCell>{row.country_code}</TableCell>
              <TableCell>{row.line}</TableCell>
              <TableCell>{row.total_impressions?.toLocaleString()}</TableCell>
              <TableCell>{row.total_clicks?.toLocaleString()}</TableCell>
              <TableCell>{Math.round(row.total_ads_cost)?.toLocaleString()}</TableCell>
              <TableCell>{Math.round(row.total_ads_sales)?.toLocaleString()}</TableCell>
              <TableCell>{row.total_ads_orders?.toLocaleString()}</TableCell>
              <TableCell>{row.total_units?.toLocaleString()}</TableCell>
              <TableCell>{row.total_orders?.toLocaleString()}</TableCell>
              <TableCell>{Math.round(row.total_sales)?.toLocaleString()}</TableCell>
              <TableCell>{(row.ctr)}%</TableCell>
              <TableCell>{(row.cpc)}%</TableCell>
              <TableCell>{(row.conversion_rate)}%</TableCell>
              <TableCell>{(row.acos)}%</TableCell>
              <TableCell>{(row.tacos)}%</TableCell>
              </TableRow>
              ))}
            <TableRow sx={{ '& td': {  fontSize: '1rem', fontWeight: '500', backgroundColor: '#f5f5f5' } }}>
                        <TableCell>{changeRow.period}</TableCell>
                        <TableCell>{changeRow.country_code}</TableCell>
                        <TableCell>{changeRow.line}</TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_impressions) }}> {changeRow.total_impressions}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_clicks) }}> {changeRow.total_clicks}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_ads_cost) }}> {changeRow.total_ads_cost}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_ads_sales) }}> {changeRow.total_ads_sales}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_ads_orders) }}> {changeRow.total_ads_orders}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_units) }}> {changeRow.total_units}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_orders) }}> {changeRow.total_orders}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.total_sales) }}> {changeRow.total_sales}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.ctr) }}> {changeRow.ctr}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.cpc) }}> {changeRow.cpc}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.conversion_rate) }}> {changeRow.conversion_rate}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.acos) }}> {changeRow.acos}% </TableCell>
                        <TableCell sx={{ color: getChangeColor(changeRow.tacos) }}> {changeRow.tacos}% </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
  };

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Scratchpad Feed</Typography>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <>
          <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
            <AgGridReact
              rowData={notes}
              columnDefs={columns}
              defaultColDef={gridOptionsDefault.defaultColDef}
              {...gridOptionsDefault}
              sideBar={AGGridSidebar}
              animateRows={true}
              context={{ openCompareModal: handleCompareModalOpen }}
            />
          </div>
  
          {/* Compare Modal */}
          <Dialog 
          open={isCompareModalOpen} 
          onClose={handleCompareModalClose}
          maxWidth="xl"
          fullWidth
  PaperProps={{
    sx: {
      minWidth: '1100px', 
      maxWidth: '1500px', 
      minHeight: '300px', 
      maxHeight: '80vh',  
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      padding: '10px',
      '& .MuiDialogTitle-root': {
        backgroundColor: '#f5f5f5',
        color: '#333333',          
      },
      '& .MuiDialogContent-root': {
        // padding: '60px',  
        fontSize: '1.5rem',
        padding: '16px 24px',
        borderBottom: '1px solid #e0e0e0'        
      }
    }
  }}
>
  <DialogTitle sx={{  borderBottom: '6px solid #e0e0e0', fontSize: '1.5rem', fontWeight: 'bold' }}>
     Comparison
      {isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
      </DialogTitle>
      <DialogContent>
      {!compareResults ? (
      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField name="country" label="Country" value={compareData.country} onChange={handleInputChange} />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField name="line" label="Line" value={compareData.line} onChange={handleInputChange} />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField name="days" label="Days" type="number"
            value={compareData.days}
            onChange={handleInputChange}
            placeholder="Default: 10 days"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            name="startDate" label="Start Date" type="date"
            value={compareData.startDate} onChange={handleInputChange}
            InputLabelProps={{ shrink: true,
            }}
          />
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            onClick={handleCompareModalClose}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    ) : (
      // Results View
      <Box sx={{ mt: 2 }}>
        <CompareResults data={compareResults} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setCompareResults(null); 
            }}
          >
            New Comparison
          </Button>
          <Button
            variant="outlined"
            onClick={handleCompareModalClose}
            sx={{ ml: 2 }}
          >
            Close
          </Button>
        </Box>
      </Box>
    )}
    </DialogContent>
    </Dialog>
        </>
      )}
    </div>
  );
};

export default ScratchpadFeed; 