import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AGGrid_Options from '../../components/global/AGGrid_Options.jsx';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar.jsx';

  const ImageCellRenderer = (props) => {
    const handleClick = (e) => {
      if (props.value) props.context.handlePreviewImage(props.value, e.clientX, e.clientY);
    };
    return props.value ? (<img src={props.value} alt="Main Image" style={{ width: '20px', height: '20px', cursor: 'pointer' }} onClick={handleClick} />) : null;
    };

    const asinColumns = [
  { headerName: "",  field: "cb",  width: 35, 
    checkboxSelection: (params) => { return !!params.data;
        }, headerCheckboxSelection: true,  suppressMenu: true, filter : false,
        cellRenderer: (params) => { if (!params.data) { return ''; }
        return params.value;  } },
  { headerName: "",  field: "link",  sortable: true, filter: false,  width: 40, 
    cellRenderer: (params) => { if (params.data && params.data.channel && params.data.asin) {
          const link = `https://www.${params.data.channel}/dp/${params.data.asin}`;
          return ( <a href={link}  target="_blank"  rel="noopener noreferrer"  style={{ textDecoration: 'none' }} > 🔗 </a>
          ); } return '';
  }},
  { headerName: "Ctry", field: "country", sortable: true, filter: true, width: 50, enableRowGroup: true },
  { headerName: "Campaign Name", field: "campaign_name", sortable: true, filter: true, Width: 280, enableRowGroup: true },
  { headerName: "Line", field: "line", sortable: true, filter: true, width: 80, enableRowGroup: true },
  { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 55, enableRowGroup: true },
  { headerName: "Search Term (ASIN)", field: "asin", sortable: true, filter: 'agTextColumnFilter', width: 120,
    filterParams: {
      filterOptions: [
        { displayKey: 'multipleAsins', displayName: 'Multiple ASINs',
          predicate: ([filterValue], cellValue) => {
            if (!filterValue) return true;
            const asins = filterValue.replace(/\s*,\s*/g, ' ')
                                     .split(/\s+/)            
                                     .filter(asin => asin !== '');
            return asins.includes(cellValue);
          }, numberOfInputs: 1, } ], }
  },
  { headerName: "Brand", field: "brand", sortable: true, filter: true, width: 150, enableRowGroup: true },
  { headerName: "Product Type", field: "producttype", sortable: true, filter: true, width: 180, enableRowGroup: true},
  { headerName: "Title", field: "itemName", sortable: true, filter: true, width: 180 },
  { headerName: "Main Image", field: "image_0", sortable: true, filter: true, width: 65,cellStyle: { textAlign: 'center' }, cellRenderer: ImageCellRenderer },
{ headerName: "Clicks", field: "clicks", sortable: true, filter: 'agNumberColumnFilter', width: 70, cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
  valueFormatter: (params) => { if (params.value !== null && params.value !== undefined) { return params.value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }); } return ''; } },
{ headerName: "Cost", field: "cost", sortable: true, filter: 'agNumberColumnFilter', width: 65, cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
  valueFormatter: (params) => { if (params.value !== null && params.value !== undefined) { return params.value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }); } return ''; } },
{ headerName: "Ads Orders", field: "ads_orders7d", sortable: true, filter: 'agNumberColumnFilter', width: 70, cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
  valueFormatter: (params) => { if (params.value !== null && params.value !== undefined) { return params.value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }); } return ''; } },
  { headerName: "ACoS", field: "acos7d", sortable: true, filter: 'agNumberColumnFilter', width: 65, cellStyle: { textAlign: 'right' }, 
  valueFormatter: (params) => { if (params.value !== null && params.value !== undefined) { return params.value.toFixed(0);  } return '';} }
];

function AsinSearch() {
    const [asinTargets, setAsinTargets] = useState([]);
    const gridRef = useRef(null);
    const [previewInfo, setPreviewInfo] = useState({ show: false, src: '', x: 0, y: 0 });
    const handlePreviewImage = (src, x, y) => setPreviewInfo({ show: true, src, x, y });
    
    const fetchAsinTargets = async () => {
      try {
        const response = await fetch('/api/search/asin_targers', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({}) });
        const result = await response.json();
        if (response.ok) {
          const dataWithIds = result.map((row, index) => ({ ...row, id: index }));
          setAsinTargets(dataWithIds);
        } else {
          console.error("Error fetching ASIN targets:", result.error);
        }
      } catch (error) {
        console.error("Error fetching ASIN targets:", error.message);
      }
    };

  useEffect(() => { fetchAsinTargets(); }, []);

  const handleExportCSV = useCallback(() => {
    if (gridRef.current) gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    if (gridRef.current) gridRef.current.api.setFilterModel(null);
  }, []);

  const handleCopySelected = useCallback(() => {
    const api = gridRef.current.api;
    const selectedAsins = [];
    for (let i = 0; i < api.getDisplayedRowCount(); i++) {
        const rowNode = api.getDisplayedRowAtIndex(i);
        if (rowNode.selected && rowNode.data && rowNode.data.asin) {
            selectedAsins.push(rowNode.data.asin); }
    }
    
    const uniqueAsins = [...new Set(selectedAsins)];
    const textToCopy = uniqueAsins.join(",");
    
    if (uniqueAsins.length > 0) {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log(`Copied ${uniqueAsins.length} unique filtered ASINs:`, textToCopy);
            })
            .catch(err => {
                console.error("Copy error:", err);
            });
    } else { console.log("No ASINs selected from filtered rows"); }
    }, []);

  const ImagePreview = ({ src, style }) => {
    return (
      <Box
        sx={{
          position: 'fixed',
          zIndex: 9999,
          maxWidth: '500px',
          maxHeight: '500px',
          border: '2px solid #ccc',
          borderRadius: '4px',
          backgroundColor: 'white',
          padding: '4px',
          pointerEvents: 'none',
        //   ...style, 
        }}
      >
        <img
          src={src}
          alt="Preview"
          style={{ maxWidth: '100%', maxHeight: '100%', display: 'block',
          }}
        />
      </Box>
    );
  };
  

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
        <Typography variant="h4" >ASIN Search</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px 20px', borderBottom: '1px solid #e0e0e0', marginBottom: '10px' }}>
        <Button variant="contained" onClick={handleCopySelected} sx={{ marginRight: '10px', fontWeight: 'bold', 
          backgroundColor: '#90caf9', '&:hover': { backgroundColor: '#42a5f5', color: 'white' }, color: 'black' }}>Copy ASINs</Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV} sx={{ marginRight: '10px' }}>Export CSV</Button>
          <Button variant="contained" color="secondary" onClick={handleClearFilters}>Clear Filters</Button>
        </Box>
      </Box>
      <div className="ag-theme-alpine" style={{ flex: 1, marginLeft: '0px', width: '100%' }}>
        <AgGridReact ref={gridRef} columnDefs={asinColumns} rowData={asinTargets} {...AGGrid_Options} sideBar={AGGrid_Sidebar} context={{ handlePreviewImage }} clipboardDelimiter="," />
      </div>
      {previewInfo.show && (
  <div onClick={() => setPreviewInfo({ show: false, src: '', x: 10, y: 10 })} style={{ position: 'fixed', left : 1500, top: 100, width: '100%', height: '100%', zIndex: 9998 }}>
    <ImagePreview src={previewInfo.src} style={{ position: 'absolute', right: `${previewInfo.x}px`, top: `${previewInfo.y}px` }} />
  </div>
    )}
    </div>
  );
}

export default AsinSearch;