import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columns, columnSets } from './lineDetailsColumns.js';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import AGGrid_Options from '../../components/global/AGGrid_Options.jsx';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar.jsx';

class TransposedCopyMenuItem {
  init(params) {
    this.params = params;
    this.gui = document.createElement('div');
    this.gui.innerHTML = `
      <span class="ag-menu-option-part ag-menu-option-text">
        Copy with Headers (Transposed)
      </span>
    `;
  }

  getGui() {
    return this.gui;
  }

  onClick() {
    const gridApi = this.params.api;
    const rangeSelection = gridApi.getCellRanges();
    
    if (!rangeSelection || rangeSelection.length === 0) return;
    
    const range = rangeSelection[0];
    const startRow = range.startRow.rowIndex;
    const endRow = range.endRow.rowIndex;
    const columns = range.columns;
    
    // Get headers
    const headers = columns.map(col => col.getColDef().headerName || col.getColDef().field);
    
    // Get data
    const data = [];
    for (let rowIdx = startRow; rowIdx <= endRow; rowIdx++) {
      const rowNode = gridApi.getDisplayedRowAtIndex(rowIdx);
      if (rowNode) {
        const rowData = columns.map(col => {
          const value = gridApi.getValue(col.getColId(), rowNode);
          return value !== undefined && value !== null ? value.toString() : '';
        });
        data.push(rowData);
      }
    }
    
    // Transpose the data
    const transposed = [headers, ...data[0].map((_, colIndex) => 
      data.map(row => row[colIndex])
    )];
    
    // Convert to TSV format
    const tsvContent = transposed.map(row => row.join('\t')).join('\n');
    
    // Copy to clipboard
    navigator.clipboard.writeText(tsvContent);
  }

  configureDefaults() {
    return {
      suppressKeyboardEvent: true,
      suppressMouseEvent: false,
    };
  }
}

const LineDetails = () => {
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [selectedAlert, setSelectedAlert] = useState('');
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');
  const [selectedView, setSelectedView] = useState('default');
  const [columnDefs, setColumnDefs] = useState(columnSets.default);

  function multiFieldSort(data) {
    return data.sort((a, b) => {
      if (a.country_code < b.country_code) return -1;
      if (a.country_code > b.country_code) return 1;
      if (a.line < b.line) return -1;
      if (a.line > b.line) return 1;
      return 0;
    });
  }

  useEffect(() => {
    document.title = "Line Details | Encasa HQ";
    console.log('Initiating Line Details data fetch');
    fetch('/api/line-details/get-details')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          console.log('Number of rows received:', data.length);
          console.log('First row data:', JSON.stringify(data[0], null, 2));
          
          const processedData = data.map(row => {
            const processedRow = {};
            for (const [key, value] of Object.entries(row)) {
              if (key === 'fba_stock') {
                // Convert fba_stock to a number, or set to 0 if it's not a valid number
                processedRow[key] = Number(value) || 0;
              } else {
                processedRow[key] = value ?? null; // Replace undefined with null for other fields
              }
            }
            return processedRow;
          });
          // setRowData(processedData);
          const sortedData = multiFieldSort(processedData);
          setRowData(sortedData);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array or is empty');
        }
      })
      .catch(error => {
        console.error('Error fetching Line Details data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setAppliedQuickFilter('');
    setSelectedAlert('');
  }, []);

  const handleQuickFilterChange = useCallback((filter) => {
    setAppliedQuickFilter(filter);
  }, []);

  const handleAlertChange = (event) => {
    setSelectedAlert(event.target.value);
    if (event.target.value) {
      let filterModel = {
        alerts: {
          filterType: 'text',
          type: 'equals',
          filter: event.target.value,
          filterModifier: 'OR'
        }
      };

      // Additional filters for specific alerts
      switch (event.target.value) {
        case 'YoY Decrease':
          filterModel.yoy_delta = {
            filterType: 'number',
            type: 'lessThan',
            filter: 0
          };
          break;
        case 'YoY Increase':
          filterModel.yoy_delta = {
            filterType: 'number',
            type: 'greaterThan',
            filter: 0
          };
          break;
        case 'MoM Decrease':
          filterModel.mom_delta = {
            filterType: 'number',
            type: 'lessThan',
            filter: 0
          };
          break;
        case 'MoM Increase':
          filterModel.mom_delta = {
            filterType: 'number',
            type: 'greaterThan',
            filter: 0
          };
          break;
        case 'High ACOS':
          filterModel.l7_acos = {
            filterType: 'number',
            type: 'greaterThan',
            filter: 0.3 // Adjust this threshold as needed
          };
          break;
        case 'High TACOS':
        case 'Very High TACOS':
          filterModel.l7_tacos = {
            filterType: 'number',
            type: 'greaterThan',
            filter: 0.5 // Adjust this threshold as needed
          };
          break;
        case 'Low TACOS':
          filterModel.l7_tacos = {
            filterType: 'number',
            type: 'lessThan',
            filter: 0.2 // Adjust this threshold as needed
          };
          break;
        case 'High DOC':
          filterModel.doc = {
            filterType: 'number',
            type: 'greaterThan',
            filter: 90 // Adjust this threshold as needed
          };
          break;
        case 'Loss Making':
          filterModel.npm_month_2 = {
            filterType: 'number',
            type: 'lessThan',
            filter: 0
          };
          break;
        case 'Profit Making':
          filterModel.npm_month_2 = {
            filterType: 'number',
            type: 'greaterThan',
            filter: 0
          };
          break;
        case 'Low CVR':
          filterModel.recalculated_cvr = {
            filterType: 'number',
            type: 'lessThan',
            filter: 0.1 // Adjust this threshold as needed
          };
          break;
        case 'No Ad Spend':
          filterModel.l7_ad_spend = {
            filterType: 'number',
            type: 'equals',
            filter: 0
          };
          break;
        // Add more cases as needed
      }

      // Apply the filter model
      gridRef.current.api.setFilterModel(filterModel);

      // Define sorting based on the selected alert
      let sortModel = [];
      switch (event.target.value) {
        case 'YoY Decrease':
          sortModel = [{ colId: 'yoy_delta', sort: 'asc' }];
          break;
        case 'YoY Increase':
          sortModel = [{ colId: 'yoy_delta', sort: 'desc' }];
          break;
        case 'MoM Decrease':
          sortModel = [{ colId: 'mom_delta', sort: 'asc' }];
          break;
        case 'MoM Increase':
          sortModel = [{ colId: 'mom_delta', sort: 'desc' }];
          break;
        case 'High ACOS':
          sortModel = [{ colId: 'l7_acos', sort: 'desc' }];
          break;
        case 'High TACOS':
        case 'Very High TACOS':
          sortModel = [{ colId: 'l7_tacos', sort: 'desc' }];
          break;
        case 'Low TACOS':
          sortModel = [{ colId: 'l7_tacos', sort: 'asc' }];
          break;
        case 'High DOC':
          sortModel = [{ colId: 'doc', sort: 'desc' }];
          break;
        case 'Loss Making':
          sortModel = [{ colId: 'npm_month_2', sort: 'asc' }];
          break;
        case 'Profit Making':
          sortModel = [{ colId: 'npm_month_2', sort: 'desc' }];
          break;
        case 'High ADR':
          sortModel = [{ colId: 'l7_adr', sort: 'desc' }];
          break;
        case 'Low ADR':
          sortModel = [{ colId: 'l7_adr', sort: 'asc' }];
          break;
        case 'Low CVR':
          sortModel = [{ colId: 'recalculated_cvr', sort: 'asc' }];
          break;
        case 'No Ad Spend':
          sortModel = [{ colId: 'l7_ad_spend', sort: 'asc' }];
          break;
        default:
          break;
      }

      // Apply the sort model
      gridRef.current.api.applyColumnState({
        state: sortModel,
        defaultState: { sort: null },
      });
    } else {
      gridRef.current.api.setFilterModel(null);
      gridRef.current.api.applyColumnState({
        defaultState: { sort: null },
      });
    }
  };

  const alertOptions = [
    'YoY Decrease', 'YoY Increase', 'MoM Decrease', 'MoM Increase',
    'High ACOS', 'High TACOS', 'Low TACOS', 'Very High TACOS',
    'High DOC', 'Stock/Grouping Issues', 'Loss Making', 'Profit Making',
    'High ADR', 'Low ADR', 'Low CVR', 'No Ad Spend'
  ];

  const gridOptions = {
    getContextMenuItems: (params) => {
      const result = [
        {
          name: 'Copy with Headers (Transposed)',
          action: () => params.api.getContext().transposedCopyMenuItem.onClick(),
          icon: '<span class="ag-icon ag-icon-copy"></span>',
          cssClasses: ['custom-menu-item'],
        },
        'separator',
        ...params.defaultItems,
      ];
      return result;
    },

    context: {
      transposedCopyMenuItem: new TransposedCopyMenuItem(),
    },
  };

  const handleViewChange = (event) => {
    const viewType = event.target.value;
    console.log('Changing view to:', viewType);
    setSelectedView(viewType);
    setColumnDefs(columnSets[viewType]);
  };

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Details</Typography>
        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Select View</InputLabel>
            <Select
              value={selectedView}
              onChange={handleViewChange}
              label="Select View"
            >
              <MenuItem value="default">Default View</MenuItem>
              <MenuItem value="profit">Profit View</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Filter by Alert</InputLabel>
            <Select
              value={selectedAlert}
              onChange={handleAlertChange}
              label="Filter by Alert"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {alertOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV} style={{ marginRight: '10px' }}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            {...AGGrid_Options}
            sideBar={AGGrid_Sidebar}
            quickFilterText={appliedQuickFilter}
            gridOptions={gridOptions}
          />
        </div>
      )}
    </div>
  );
};

export default LineDetails;