import React from 'react';

export const columns = [
    { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 120 },
    { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 160 },
    { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 130 },
    { headerName: "Risk Flag", field: "risk_flag", sortable: true, filter: true, width: 120 },
    { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width: 120 },
    { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width: 120 },
    { headerName: "Missed Days", field: "missed_days", sortable: true, filter: true, width: 120 },
    { headerName: "Inv At Risk", field: "inv_at_risk", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center'} },
    { headerName: "Inv Short LTSF", field: "inv_short_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center'} },
    { headerName: "Inv Long LTSF", field: "inv_long_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center'} },
    { headerName: "Live Price", field: "live_price", sortable: true, filter: true, width: 120 },
    { headerName: "CC Price", field: "price_cc", sortable: true, filter: true, width: 120 },
    { headerName: "Dynamic Price", field: "dynamic_price", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center'},
    cellClass: params => { if (!params.data ||  params.data.dynamic_price === undefined ||  params.data.price_cc === undefined) { return ''; }
      const dynamicPrice = Number(params.data.dynamic_price); const ccPrice = Number(params.data.price_cc);
      console.log('Dynamic Price:', dynamicPrice, 'CC Price:', ccPrice, 'Comparison:', dynamicPrice < ccPrice);
      if (dynamicPrice > ccPrice) { return 'ag-cell-green'; }
      if (dynamicPrice < ccPrice) { return 'ag-cell-red'; } return ''; },
    valueFormatter: params => params.value ?  params.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
    },
    { field: "rule", headerName: "Rule", flex: 1, sortable: true, filter: true, minWidth: 250},
    { headerName: "Min Price", field: "min_price", sortable: true, filter: true, width: 120, editable: true  }
  ];


export const columns_sku = [
  { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 },
  { headerName: "Line", field: "line", sortable: true, filter: true, width: 120 },
  { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 160 },
  { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 130 },
  { headerName: "Risk Flag", field: "risk_flag", sortable: true, filter: true, width: 120 },
  { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width: 120 },
  { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width: 120 },
  { headerName: "Missed Days", field: "missed_days", sortable: true, filter: true, width: 120 },
  { headerName: "Inv At Risk", field: "inv_at_risk", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center'} },
  { headerName: "Inv Short LTSF", field: "inv_short_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center'} },
  { headerName: "Inv Long LTSF", field: "inv_long_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'center'} },
  { headerName: "Live Price", field: "live_price", sortable: true, filter: true, width: 120 },
  { headerName: "CC Price", field: "price_cc", sortable: true, filter: true, width: 120 },
  { headerName: "Dynamic Price", field: "dynamic_price", sortable: true, filter: 'agNumberColumnFilter', width: 120, editable: true  },
  { field: "rule", headerName: "Rule", flex: 1, sortable: true, filter: true, minWidth: 250},
  { headerName: "Min Price", field: "min_price", sortable: true, filter: true, width: 120, editable: true  }
];

export const BQ_Repricing_Log_Columns = [
    { field: "country", headerName: "Country", sortable: true, filter: true, width : 110,pinned : 'left' },
    { field: "sku", headerName: "SKU", flex: 1,sortable: true, filter: true, minWidth : 170, pinned: 'left' },
    { headerName: "Link", field: "link", sortable: true, filter: true, width: 95, pinned: 'left', enableRowGroup: true, cellStyle: { textAlign: 'center' },
    cellRenderer: params => { if (!params.value) { return null; } const url = params.value.startsWith('http') ? params.value : `https://${params.value}`; 
    return React.createElement('a', { href: url, target: '_blank', rel: 'noopener noreferrer', style: { textDecoration: 'none'}  }, '🔗');  } },
    { field: "asin", headerName: "Asin",sortable: true, filter: true, width : 150},
    { field: "line", headerName: "Line", sortable: true, filter: true, width : 120},
    { headerName : "Log Info",
      children : [
    { field: "time_id", headerName: "Time ID", flex: 1,sortable: true, filter: true, minWidth : 150 },
    { field: "feedId", headerName: "Feed ID", flex: 1,sortable: true, filter: true, minWidth : 150 },
    { field: "operationType", headerName: "Operation Type", flex: 1,sortable: true, minWidth : 120, filter: true, columnGroupShow: 'open' },
    { field: "message_id", headerName: "Message ID", flex: 1,sortable: true, filter: true, minWidth : 100, columnGroupShow: 'open' },
    ]},
    { headerName: 'Stocks',
      children: [
    { field: "fba_stock", headerName: "FBA Stock" ,sortable: true, filter: 'agNumberColumnFilter', width : 130,cellStyle: { textAlign: 'center'} },
    { field: "ma", headerName: "MA", sortable: true, filter: 'agNumberColumnFilter', width : 130,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { field: "risk_flag", headerName: "Risk Flag",sortable: true, filter: true, width : 140},
    { field: "doc", headerName: "DOC",sortable: true, filter: 'agNumberColumnFilter', width : 130,cellStyle: { textAlign: 'center'} },
    { field: "missed_days", headerName: "Missed Days", sortable: true, filter: 'agNumberColumnFilter', width : 130,cellStyle: { textAlign: 'center'} },
    { headerName: "Inv At Risk",field: "inv_at_risk" , sortable: true, filter: 'agNumberColumnFilter', width : 130,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { headerName: "Inv Short LTSF", field: "inv_short_ltsf",sortable: true, filter: 'agNumberColumnFilter', width : 130,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    { headerName: "Inv Long LTSf", field: "inv_long_ltsf", sortable: true, filter: 'agNumberColumnFilter', width : 130,cellStyle: { textAlign: 'center'}, columnGroupShow: 'open' },
    ]},
    
    {headerName: 'Pricing',
    children: [
    { field: "live_price", headerName: "Live Price", flex: 1,sortable: true, minWidth : 140, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'center'} },
    { field: "reqd_price", headerName: "Reqd Price", flex: 1,sortable: true, minWidth : 140, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'center'} },
    { field: "dynamic_price", headerName: "Dynamic Price", flex: 1, sortable: true, minWidth : 140, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'center'},
    cellClass: params => {
        if (!params.data || params.data.dynamic_price === undefined || params.data.reqd_price === undefined) { return ''; }
        if (params.data.dynamic_price > params.data.reqd_price) { return 'ag-cell-green'; }
        if (params.data.dynamic_price < params.data.reqd_price) { return 'ag-cell-red'; } return '';},
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', 
      aggFunc: 'sum' 
    },
    { field: "rule", headerName: "Rule", flex: 1, sortable: true, filter: true, minWidth: 320}
    ]}
  ];