export const columns = [
    {
        field: 'country',
        headerName: 'Ctry',
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 60,
        pinned: 'left',
        enableRowGroup: true,
    },
    {
        field: 'line',
        headerName: 'Line',
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 70,
        pinned: 'left',
        enableRowGroup: true,
    },
    {
        field: 'search_term',
        headerName: 'Search Term',
        filter: 'agTextColumnFilter',
        width: 250,
        pinned: 'left',
        enableRowGroup: true,
    },
    {
        field: 'label',
        headerName: 'Label',
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 120,
        pinned: 'left',
        enableRowGroup: true,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: [
                'generic',
                'highly_relevant',
                'competitor',
                'asin',
                'seasonal',
                'size',
                'color',
                'size_color',
                'pack_pcs',
                'irrelevant'
            ]
        }
    },
    {
        field: 'dri',
        headerName: 'DRI',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true,
            suppressMiniFilter: false,
        },
        width: 60,
        enableRowGroup: true,
    },
    {
        field: 'clicks',
        headerName: 'Clicks',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 80,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        aggFunc: 'sum'
    },
    {
        field: 'orders',
        headerName: 'Orders',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 80,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        aggFunc: 'sum'
    },
    {
        field: 'acos',
        headerName: 'ACOS',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 80,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? (params.value * 100).toFixed(0) + '%' : '',
        aggFunc: 'avg'
    },
    {
        field: 'label_source',
        headerName: 'Label Source',
        filter: 'agTextColumnFilter',
        width: 120,
        enableRowGroup: true,
    },
    {
        field: 'confidence_score',
        headerName: 'Confidence',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 120,
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => params.value ? (params.value * 100).toFixed(0) + '%' : '',
        aggFunc: 'avg'
    },
    {
        field: 'assigned_at',
        headerName: 'Assigned At',
        filter: 'agDateColumnFilter',
        width: 140,
        valueFormatter: params => params.value ? new Date(params.value).toLocaleString() : '',
    }
];