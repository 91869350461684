import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Paper, Typography, FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const ProductLineInfo = ({ initialCountry, initialLine, onClose, onSave }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [countries, setCountries] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(initialCountry || '');
  const [selectedLine, setSelectedLine] = useState(initialLine || '');
  const [kypData, setKypData] = useState({
    product_notes: '',
    customer_notes: '',
    blacklisted_words: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    console.log("Component mounted, initializing data fetch");
    // Get URL parameters only if no initial values provided
    if (!initialCountry || !initialLine) {
      const params = new URLSearchParams(window.location.search);
      const countryParam = params.get('country');
      const lineParam = params.get('line');

      if (countryParam) setSelectedCountry(countryParam);
      if (lineParam) setSelectedLine(lineParam);
    }

    // Fetch countries and lines
    fetchCountriesAndLines().then(() => {
      setIsInitialized(true);
    });
  }, [initialCountry, initialLine]);

  useEffect(() => {
    if (!isInitialized) {
      return; // Wait for initialization
    }

    // Fetch data after initialization
    console.log(`Fetching data - country: ${selectedCountry}, line: ${selectedLine}`);
    fetchKypData();
  }, [isInitialized, selectedCountry, selectedLine]);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };

  const fetchCountriesAndLines = async () => {
    try {
      console.log("Fetching countries and lines data");
      const response = await fetch('/api/line-details/get-countries-lines', fetchOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const uniqueCountries = [...new Set(data.map(item => item.country))];
      const uniqueLines = [...new Set(data.map(item => item.line))];
      console.log(`Retrieved ${uniqueCountries.length} countries and ${uniqueLines.length} lines`);
      setCountries(uniqueCountries);
      setLines(uniqueLines);
    } catch (error) {
      console.error('Error fetching countries and lines:', error);
      setError(error.message);
    }
  };

  const fetchKypData = async () => {
    if (!selectedCountry || !selectedLine) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/line/kyp?country=${selectedCountry}&line=${selectedLine}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setKypData({
        product_notes: data.product_notes || '',
        customer_notes: data.customer_notes || '',
        blacklisted_words: data.blacklisted_words || ''
      });
      setError(null);
    } catch (error) {
      console.error('Error fetching KYP data:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountryChange = (event) => {
    console.log(`Country selection changed to: ${event.target.value}`);
    setSelectedCountry(event.target.value);
    
    // Update URL parameters
    const params = new URLSearchParams(window.location.search);
    if (event.target.value) {
      params.set('country', event.target.value);
    } else {
      params.delete('country');
    }
    if (selectedLine) {
      params.set('line', selectedLine);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const handleLineChange = (event) => {
    console.log(`Line selection changed to: ${event.target.value}`);
    setSelectedLine(event.target.value);
    
    // Update URL parameters
    const params = new URLSearchParams(window.location.search);
    if (event.target.value) {
      params.set('line', event.target.value);
    } else {
      params.delete('line');
    }
    if (selectedCountry) {
      params.set('country', selectedCountry);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await fetch('/api/line/kyp/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountry,
          line: selectedLine,
          ...kypData
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update KYP data');
      }

      toast.success('KYP data updated successfully');
      if (onSave) {
        onSave();
      }
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error updating KYP data:', error);
      toast.error('Error updating KYP data: ' + error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleGenerateKyp = async () => {
    if (!selectedCountry || !selectedLine) {
      alert('Please select both country and line first');
      return;
    }

    setIsGenerating(true);
    try {
      const response = await fetch('/api/line/generate_kyp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountry,
          line: selectedLine,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate KYP data');
      }

      const data = await response.json();
      if (data.success && data.response) {
        setKypData(prevData => ({
          ...prevData,
          product_notes: data.response
        }));
      } else {
        throw new Error(data.error || 'Failed to generate KYP data');
      }
    } catch (error) {
      console.error('Error generating KYP data:', error);
      alert(error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Box sx={{ 
      padding: '20px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto'
    }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Product Line Info</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          {onClose && (
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              label="Country"
              disabled={!!initialCountry}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {countries.map(country => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Line</InputLabel>
            <Select
              value={selectedLine}
              onChange={handleLineChange}
              label="Line"
              disabled={!!initialLine}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {lines.map(line => (
                <MenuItem key={line} value={line}>{line}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {error ? (
        <Typography color="error" variant="h6">Error: {error}</Typography>
      ) : (
        <Paper elevation={3} sx={{ p: 3, flex: 1, overflow: 'auto' }}>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" alignItems="center" gap={1}>
                <TextField
                  label="Product Notes"
                  multiline
                  rows={16}
                  fullWidth
                  value={kypData.product_notes}
                  onChange={(e) => setKypData({ ...kypData, product_notes: e.target.value })}
                  variant="outlined"
                />
                <Tooltip title="Generate Product Notes">
                  <IconButton 
                    onClick={handleGenerateKyp}
                    disabled={isGenerating}
                    color="primary"
                  >
                    <AutoAwesomeIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <TextField
                label="Customer Notes"
                multiline
                rows={10}
                fullWidth
                value={kypData.customer_notes}
                onChange={(e) => setKypData({ ...kypData, customer_notes: e.target.value })}
                variant="outlined"
              />

              <TextField
                label="Blacklisted Words"
                multiline
                rows={2}
                fullWidth
                value={kypData.blacklisted_words}
                onChange={(e) => setKypData({ ...kypData, blacklisted_words: e.target.value })}
                variant="outlined"
              />

              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!selectedCountry || !selectedLine || isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default ProductLineInfo; 